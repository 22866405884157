<template>
<div>
	<myheader></myheader>
	<div class="bg"></div>
	<div class="city" >

		<div v-html="view"></div>
	</div>
	<myfooter></myfooter>
	<myright></myright>
</div>
	
</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
import {bannerinfoAPI} from "@/api/index"
export default {
 components: {
    myheader, myfooter, myright
  },
  data () {
    return {
		view:""
    }
  },
  created(){
	 const id = this.$route.query.id
	  this.getactive(id)
	  
  },
 
  methods: {
    getactive(id){
		bannerinfoAPI({id}).then(res=>{
			this.view=res.content
		})
	}
  }

}
</script>

<style lang="scss" scoped="scoped">
.bg{
		height: 10px;
		min-width: 1200px;
		width: 100%;
		background: linear-gradient(#ececec,#fff);
	}
.city{
	width: 1200px;
	margin: 20px auto;
	padding-bottom: 30px;
	min-height: 500px;
	
}

</style>
